import axios from "axios";
import headers from "./Headers";

function getSkyObjects() {
  return axios.get("objects");
}

function createSkyObject(form) {
  return axios.post("objects", form, headers);
}

function showSkyObject(id) {
  return axios.get(`objects/${id}`, headers);
}

function editSkyObject(id, form) {
  return axios.patch(`objects/${id}`, form, headers);
}

function deleteSkyObject(id) {
  return axios.delete(`objects/${id}`, headers);
}

export {
  getSkyObjects,
  createSkyObject,
  showSkyObject,
  editSkyObject,
  deleteSkyObject,
};
