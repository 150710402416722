<template>
  <div
    @click.prevent="openTutorial"
    class="
      help-button
      d-none d-md-flex
      align-items-center
      justify-content-center
    "
    :class="{
      black: isBlack,
      white: isWhite,
    }"
  >
    <svg
      width="771"
      height="771"
      viewBox="0 0 771 771"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="385.5"
        cy="385.5"
        r="380.5"
        stroke="#101010"
        stroke-width="10"
        stroke-dasharray="100 100"
      />
    </svg>

    <span class="d-flex justify-content-center align-items-center">?</span>
  </div>
</template>

<script>
export default {
  name: "HelpButton",
  props: {
    isBlack: Boolean,
    isWhite: Boolean,
  },
  methods: {
    openTutorial() {
      document.querySelector(".tutorial").classList.add("active");
    },
  },
};
</script>