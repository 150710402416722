<template>
  <div ref="tutorial" class="tutorial">
    <div class="container">
      <div class="tutorial-item active">
        <div class="row justify-content-center">
          <div class="col-6 col-md-4 col-lg-3">
            <div class="icon">
              <Lottie
                :options="dblClickOptions"
                v-on:animCreated="handleDblClick"
              />
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col-md-6 text-center">
            <h4>Double Click</h4>

            <p
              data-en="Double-click on the planet to see <br />
              an explanation"
              data-id="Klik planet dua kali untuk melihat <br /> penjelasannya"
              class="mt-2 mb-4"
            >
              Double-click on the planet to see <br />
              an explanation
            </p>

            <a @click.prevent="nextTutorial" class="btn btn-outline-white px-5">
              OK
            </a>
          </div>
        </div>
      </div>

      <div class="tutorial-item">
        <div class="row justify-content-center">
          <div class="col-6 col-md-4 col-lg-3">
            <div class="icon">
              <Lottie
                :options="zoomOutOptions"
                v-on:animCreated="handleZoomOut"
              />
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col-md-6 text-center">
            <h4>Zoom Out</h4>

            <p
              data-en="Zoom out to see other planets."
              data-id="Perkecil untuk melihat planet lain."
              class="mt-2 mb-4"
            >
              Zoom out to see other planets.
            </p>

            <a @click.prevent="nextTutorial" class="btn btn-outline-white px-5">
              OK
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Plugins
import Lottie from "@/components/plugins/Lottie.vue";

// Animations
import * as dblClick from "../../../public/assets/animations/dbl-click.json";
import * as zoomOut from "../../../public/assets/animations/zoom-out.json";

export default {
  name: "TutorialObject",
  components: {
    Lottie,
  },
  data() {
    return {
      dblClickOptions: {
        animationData: dblClick.default,
        loop: true, // Optional
        autoplay: true, // Optional
      },
      zoomOutOptions: {
        animationData: zoomOut.default,
        loop: true, // Optional
        autoplay: true, // Optional
      },
    };
  },
  methods: {
    handleDblClick(anim) {
      this.dblClick = anim;
    },
    handleZoomOut(anim) {
      this.zoomOut = anim;
    },
    nextTutorial() {
      const currentItem = this.$refs.tutorial.querySelector(".active");
      const prevSibling = currentItem.previousSibling;
      const nextSibling = currentItem.nextSibling;

      if (nextSibling) {
        currentItem.classList.remove("active");
        nextSibling.classList.add("active");
      } else {
        this.$refs.tutorial.classList.remove("active");
        this.$store.dispatch("tutorial/setTutorial", {
          key: "skyObject",
          value: true,
        });

        setTimeout(() => {
          currentItem.classList.remove("active");
          prevSibling.classList.add("active");
        }, 1000);
      }
    },
  },
};
</script>

<style>
</style>