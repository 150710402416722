import * as THREE from "three";

export default function Stars(scene) {
  const starsMaterials = [
    new THREE.PointsMaterial({
      color: 0x555555,
      size: 2,
      sizeAttenuation: false,
    }),
    new THREE.PointsMaterial({
      color: 0x555555,
      size: 1,
      sizeAttenuation: false,
    }),
    new THREE.PointsMaterial({
      color: 0x333333,
      size: 2,
      sizeAttenuation: false,
    }),
    new THREE.PointsMaterial({
      color: 0x3a3a3a,
      size: 1,
      sizeAttenuation: false,
    }),
    new THREE.PointsMaterial({
      color: 0x1a1a1a,
      size: 2,
      sizeAttenuation: false,
    }),
    new THREE.PointsMaterial({
      color: 0x1a1a1a,
      size: 1,
      sizeAttenuation: false,
    }),
  ];

  for (let i = 0; i < starsMaterials.length; i++) {
    const vertices = randomGeometry();

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );

    const points = new THREE.Points(geometry, starsMaterials[i]);

    scene.add(points);
  }

  this.update = function () {
    // do something
  };
}

function randomGeometry(numPoints = 250) {
  const vertices = [];

  for (var i = 0; i < numPoints; i++) {
    var x = THREE.MathUtils.randFloatSpread(2000);
    var y = THREE.MathUtils.randFloatSpread(2000);
    var z = THREE.MathUtils.randFloatSpread(2000);

    vertices.push(x, y, z);
  }

  return vertices;
}
