import * as THREE from "three";

export default function SolarSystem(scene) {
  let resourceLoad = false;

  const loadingManager = new THREE.LoadingManager();

  // loadingManager.onProgress = function (item, loaded, total) {
  //   console.log(item, loaded, total);
  // };

  loadingManager.onLoad = function () {
    resourceLoad = true;
  };

  const loader = new THREE.TextureLoader(loadingManager);

  var astrionomicalBodies = [];

  let isRotate = true;

  const solarSystem = new THREE.Group();
  scene.add(solarSystem);
  var sunMesh = createSun(
    "Sun",
    40,
    scene,
    solarSystem,
    astrionomicalBodies,
    loader
  );

  var mercuryOrbit = createOrbit(solarSystem);
  var mercuryMesh = createPlanet(
    "Planet Mercury",
    3,
    60,
    scene,
    mercuryOrbit,
    astrionomicalBodies,
    loader,
    true
  );
  createOrbitLine(60, scene, astrionomicalBodies);

  var venusOrbit = createOrbit(solarSystem);
  var venusMesh = createPlanet(
    "Planet Venus",
    8,
    80,
    scene,
    venusOrbit,
    astrionomicalBodies,
    loader
  );
  createOrbitLine(80, scene, astrionomicalBodies);

  var earthOrbit = createOrbit(solarSystem);
  var earthMesh = createPlanet(
    "Planet Earth",
    10,
    110,
    scene,
    earthOrbit,
    astrionomicalBodies,
    loader
  );
  createOrbitLine(110, scene, astrionomicalBodies);

  // Cloud Earth
  const cloudEarthgeometry = new THREE.SphereGeometry(10.15, 32, 16);
  const cloudEarthMaterial = new THREE.MeshPhongMaterial({
    map: loader.load("./assets/textures/Planet Earth/cloud.png"),
    transparent: true,
  });
  const cloudEarthMesh = new THREE.Mesh(cloudEarthgeometry, cloudEarthMaterial);
  cloudEarthMesh.name = "Planet Earth Cloud";
  cloudEarthMesh.position.set(110, 0, 0);

  earthOrbit.add(cloudEarthMesh);
  astrionomicalBodies.push(cloudEarthMesh);

  var marsOrbit = createOrbit(solarSystem);
  var marsMesh = createPlanet(
    "Planet Mars",
    5,
    150,
    scene,
    marsOrbit,
    astrionomicalBodies,
    loader
  );
  createOrbitLine(150, scene, astrionomicalBodies);

  var jupiterOrbit = createOrbit(solarSystem);
  var jupiterMesh = createPlanet(
    "Planet Jupiter",
    25,
    220,
    scene,
    jupiterOrbit,
    astrionomicalBodies,
    loader,
    true
  );
  createOrbitLine(220, scene, astrionomicalBodies);

  var saturnOrbit = createOrbit(solarSystem);
  var saturnMesh = createPlanet(
    "Planet Saturn",
    17,
    320,
    scene,
    saturnOrbit,
    astrionomicalBodies,
    loader,
    true
  );
  createOrbitLine(320, scene, astrionomicalBodies);

  // Saturns ring
  const innerRadius = 26;
  const outerRadius = 32;
  const thetaSegments = 60;
  const saturnBelt = new THREE.RingBufferGeometry(
    innerRadius,
    outerRadius,
    thetaSegments
  );
  const material = new THREE.MeshPhongMaterial({
    side: THREE.DoubleSide,
    map: loader.load("./assets/textures/Planet Saturn/ring.jpg"),
  });
  const mesh = new THREE.Mesh(saturnBelt, material);
  mesh.rotation.set(5, 0, 0);

  mesh.position.set(320, 0, 0);
  saturnOrbit.add(mesh);

  var uranusOrbit = createOrbit(solarSystem);
  var uranusMesh = createPlanet(
    "Planet Uranus",
    13,
    420,
    scene,
    uranusOrbit,
    astrionomicalBodies,
    loader,
    true
  );
  createOrbitLine(420, scene, astrionomicalBodies);

  var neptuneOrbit = createOrbit(solarSystem);
  var neptuneMesh = createPlanet(
    "Planet Neptune",
    12,
    500,
    scene,
    neptuneOrbit,
    astrionomicalBodies,
    loader,
    true
  );
  createOrbitLine(500, scene, astrionomicalBodies);

  let timeRotate = Date.now();

  this.update = function () {
    cloudEarthMesh.rotation.y -= 0.0002;

    if (isRotate) {
      timeRotate += 0.015;
      rotatePlanet(timeRotate);
    }
  };

  this.isResourceLoad = function () {
    return resourceLoad;
  };

  this.getAstrionomicalBodies = function () {
    return astrionomicalBodies;
  };

  this.getPlanet = function (name) {
    return astrionomicalBodies.find((item) => item.name === name);
  };

  this.setIsRotate = function (value) {
    isRotate = value;
  };

  function rotatePlanet(time) {
    // rotate the planets
    sunMesh.rotation.y = time * 0.1;

    mercuryOrbit.rotation.y = time * 0.08 + 3.5; // rotate around the sun
    mercuryMesh.rotation.y = time * 0.1; // rotate the planet

    venusOrbit.rotation.y = time * 0.07 + 1;
    venusMesh.rotation.y = time * 0.1;

    earthOrbit.rotation.y = time * 0.06 - 0.5;
    earthMesh.rotation.y = time * 0.1;

    marsOrbit.rotation.y = time * 0.05 - 0.1;
    marsMesh.rotation.y = time * 0.1;

    jupiterOrbit.rotation.y = time * 0.045 - 1.5;
    jupiterMesh.rotation.y = time * 0.1;

    saturnOrbit.rotation.y = time * 0.04 - 1.3;
    saturnMesh.rotation.y = time * 0.1;

    uranusOrbit.rotation.y = time * 0.03 + 0.5;
    uranusMesh.rotation.y = time * 0.1;

    neptuneOrbit.rotation.y = time * 0.02 - 0.5;
    neptuneMesh.rotation.y = time * 0.1;
  }
}

function createOrbit() {
  const orbit = new THREE.Group();

  for (let i = 0, j = arguments.length; i < j; i++) {
    arguments[i].add(orbit);
  }

  return orbit;
}

function createPlanet(
  name,
  size,
  distanceX,
  scene,
  orbit,
  astrionomicalBodies,
  loader,
  isNoBump
) {
  const geometry = new THREE.SphereGeometry(size, 32, 16);
  let material;

  const texturePath = `./assets/textures/${name}/map.jpg`;

  //without callback
  const texture = loader.load(texturePath);

  if (!isNoBump) {
    const bumpPath = `./assets/textures/${name}/bump.jpg`;
    const bump = loader.load(bumpPath);
    material = new THREE.MeshPhongMaterial({
      map: texture,
      bumpMap: bump,
      bumpScale: 0.3,
    });
  } else {
    material = new THREE.MeshPhongMaterial({
      map: texture,
    });
  }

  const planetMesh = new THREE.Mesh(geometry, material);
  planetMesh.name = name;

  planetMesh.position.set(distanceX, 0, 0);

  orbit.add(planetMesh);
  astrionomicalBodies.push(planetMesh);

  return planetMesh;
}

function createSun(name, size, scene, orbit, astrionomicalBodies, loader) {
  var geometry = new THREE.SphereGeometry(size, 32, 16);

  let texturePath = `./assets/textures/${name}/map.jpg`;

  //without callback
  var texture = loader.load(texturePath);
  var material = new THREE.MeshBasicMaterial({ map: texture });
  var sunMesh = new THREE.Mesh(geometry, material);
  sunMesh.name = name;

  orbit.add(sunMesh);
  astrionomicalBodies.push(sunMesh);

  return sunMesh;
}

function createOrbitLine(distanceX, scene, astrionomicalBodies) {
  const innerRadius = distanceX - 1;
  const outerRadius = distanceX + 1;
  const thetaSegments = 80;
  const geometry = new THREE.RingBufferGeometry(
    innerRadius,
    outerRadius,
    thetaSegments
  );

  const material = new THREE.MeshBasicMaterial({
    // color: 0xf5e96c,
    color: 0xffffff,
    opacity: 0.2,
    transparent: true,
    side: THREE.DoubleSide,
  });

  var mesh = new THREE.Mesh(geometry, material);
  mesh.rotation.x = Math.PI / 2;

  astrionomicalBodies.push(mesh);
  scene.add(mesh);
}
