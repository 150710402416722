import * as THREE from "three";

export default class PickHelper {
  astronomicalBodiesNames = {};

  constructor(planets) {
    this.raycaster = new THREE.Raycaster();
    this.pickedObject = null;
    this.pickedObjectSavedColor = 0;
    this.astronomicalBodiesNames = planets;
  }

  detect(normalizedPosition, getAstrionomicalBodies, camera) {
    // cast a ray through the frustum
    this.raycaster.setFromCamera(normalizedPosition, camera);

    // get the list of objects the ray intersected
    const intersectedObjects = this.raycaster.intersectObjects(
      getAstrionomicalBodies
    );

    if (intersectedObjects.length) {
      return intersectedObjects[0].object.name;
    }
  }

  pick(normalizedPosition, getAstrionomicalBodies, camera, selectedObject) {
    // restore the color if there is a picked object
    if (this.pickedObject) {
      this.pickedObject.material.color = this.pickedObjectSavedColor;
      this.pickedObject = undefined;
    }

    // cast a ray through the frustum
    this.raycaster.setFromCamera(normalizedPosition, camera);

    // get the list of objects the ray intersected
    const intersectedObjects = this.raycaster.intersectObjects(
      getAstrionomicalBodies
    );

    if (intersectedObjects.length) {
      // pick the first object. It's the closest one
      this.pickedObject = intersectedObjects[0].object;

      if (
        !selectedObject ||
        this.pickedObject.name != selectedObject.object.name
      ) {
        // save its color
        this.pickedObjectSavedColor = this.pickedObject.material.color;
        // open modal
        // this.openModal(this.pickedObject.name);

        const point = intersectedObjects[0].point;
        const object = this.pickedObject;
        const planet =
          this.astronomicalBodiesNames[
            this.pickedObject.name == "Planet Earth Cloud"
              ? "Planet Earth"
              : this.pickedObject.name
          ];

        if (point && object && planet) {
          return { point, object, planet };
        }
      }
    }
  }

  addSprite(scene, position) {
    var spriteMap = new THREE.TextureLoader().load("assets/textures/moon.jpg");
    var spriteMaterial = new THREE.SpriteMaterial({ map: spriteMap });
    var sprite = new THREE.Sprite(spriteMaterial);
    sprite.position.set(position.x, position.y + 20, position.z);
    sprite.scale.set(10, 10, 10);
    scene.add(sprite);
  }
}
