import { Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import gsap from "gsap";

export default function MyCameraControls(camera, canvas) {
  const controls = new OrbitControls(camera, canvas);

  this.update = function () {
    controls.update();
  };

  // settings
  controls.enablePan = false;
  controls.enableDamping = true;
  controls.minDistance = 20;
  controls.maxDistance = 850;
  controls.zoomSpeed = 0.5;
  controls.autoRotate = true;
  controls.autoRotateSpeed = 0.5;

  this.viewPlanet = function (object, planet) {
    if (!gsap.isTweening(camera.position)) {
      const target = new Vector3();
      object.getWorldPosition(target);

      gsap.to(camera.position, {
        duration: 1,
        ease: "power3.inOut",
        x: target.x,
        y: target.y + 5,
        z: target.z + planet.additional_view,
      });

      gsap.to(controls.target, {
        duration: 1,
        ease: "power3.inOut",
        x: target.x,
        y: target.y,
        z: target.z,
      });

      controls.minDistance = planet.min_distance;
    }
  };

  this.viewPlanetPoint = function (point, object, planet) {
    if (!gsap.isTweening(camera.position)) {
      const target = new Vector3();
      object.getWorldPosition(target);

      gsap.to(camera.position, {
        duration: 1,
        ease: "power3.inOut",
        x: point.x,
        y: point.y,
        z: point.z + planet.additional_view,
      });

      gsap.to(controls.target, {
        duration: 1,
        ease: "power3.inOut",
        x: target.x,
        y: target.y,
        z: target.z,
      });

      controls.minDistance = planet.min_distance;
    }
  };

  this.viewSolarSystem = function () {
    if (!gsap.isTweening(camera.position)) {
      gsap.to(camera.position, {
        duration: 1,
        ease: "power3.inOut",
        x: 0,
        y: 300,
        z: 750,
      });

      gsap.to(controls.target, {
        duration: 1,
        ease: "power3.inOut",
        x: 0,
        y: 0,
        z: 0,
      });

      controls.minDistance = 20;
    }
  };
}
