import { AmbientLight as THREEAmbientLight } from "three/src/lights/AmbientLight";

export default function AmbientLight(scene) {
  const color = 0xffffff;
  const intensity = 0.08;

  const ambientLight = new THREEAmbientLight(color, intensity);
  scene.add(ambientLight);

  this.update = function () {
    // do something
  };

  //gui.addColor(new ColorGUIHelper(light, 'color'), 'value').name('color');
  //gui.add(light, 'intensity', 0.1, 2, 0.01);
}
