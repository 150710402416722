<template>
  <div class="page-sky-objects">
    <LoadingScreenObject :isCompleted="isResourceLoaded" />

    <!-- Tutorial -->
    <TutorialObject />

    <!-- Navbar -->
    <Navbar
      isWhite
      isSkyObject
      :isFullScreen="isDetail"
      v-on:exitDetail="exitDetail"
    />

    <!-- Sidebar -->
    <Sidebar />

    <!-- Main Sky Objects -->
    <canvas ref="canvas" id="canvas"></canvas>

    <div>
      <div class="container container-title">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="title">
              <h1 class="text-uppercase">
                EARTH <br />
                PLANET
              </h1>
              <h5 class="text-uppercase">THE ONLY ONE INHABITED PLANET</h5>
            </div>
          </div>

          <div class="col-md-6 mt-4 mt-md-0">
            <div class="description">
              <div class="mb-3">
                <h3 class="text-uppercase name-object">EARTH</h3>
                <span data-en="Name Object" data-id="Nama Objek"
                  >Name Object</span
                >
              </div>

              <div class="mb-3">
                <h3 class="text-uppercase early-formed">4,54 M</h3>
                <span data-en="Early Formed" data-id="Awal Terbentuk"
                  >Early Formed</span
                >
              </div>

              <div class="mb-3">
                <h3 class="text-uppercase rotation-speed">29,78 KM/S</h3>
                <span
                  data-en="Rotation Speed"
                  data-id="Kecepatan Rotasi"
                ></span>
              </div>

              <div class="mb-3">
                <h3 class="text-uppercase satelite">1 SATELITE</h3>
                <span data-en="Number of Satelite" data-id="Jumlah Satelit"
                  >Number of Satelite</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container container-arrow">
        <div class="row justify-content-between align-content-center arrow">
          <div class="col-6">
            <a
              class="
                cursor-pointer
                prev-arrow
                d-inline-flex
                align-items-center
                text-uppercase
              "
              @click.prevent="sceneManager.prevPlanet"
            >
              <font-awesome-icon :icon="['fa', 'chevron-left']" class="mr-2" />
              <span>MARS</span>
            </a>
          </div>
          <div class="col-6 text-right">
            <a
              class="
                cursor-pointer
                next-arrow
                d-inline-flex
                align-items-center
                text-uppercase
              "
              @click.prevent="sceneManager.nextPlanet"
            >
              <span>NEPTUNE</span>
              <font-awesome-icon :icon="['fa', 'chevron-right']" class="ml-2" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-sky">
      <!-- Footer -->
      <Footer isWhite isSmall />
    </div>

    <!-- Help Mobile -->
    <HelpButton isWhite />
  </div>
</template>

<script>
// Library
import { mapState } from "vuex";

// Layouts
import Navbar from "@/components/layouts/Navbar.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";
import Footer from "@/components/layouts/Footer.vue";

// Components
import LoadingScreenObject from "@/components/components/LoadingScreenObject";
import TutorialObject from "@/components/components/TutorialObject";
import HelpButton from "@/components/components/HelpButton.vue";

// Services
import SceneManager from "@/services/solar-system/SceneManager.js";
import { getSkyObjects } from "@/services/admin/SkyObjects.js";

export default {
  name: "SkyObjects",
  metaInfo: {
    title: "Sky Objects - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  components: {
    Navbar,
    Sidebar,
    Footer,
    LoadingScreenObject,
    TutorialObject,
    HelpButton,
  },
  computed: {
    ...mapState("tutorial", {
      skyObject: (state) => state.skyObject,
    }),
  },
  data() {
    return {
      planets: {},
      sceneManager: {},
      isDestroy: false,
      isResourceLoaded: false,
      isDetail: false,
    };
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    this.isDestroy = true;
    this.sceneManager.dispose();
  },
  watch: {
    isResourceLoaded(val) {
      if (val) {
        if (!this.skyObject) {
          document.querySelector(".tutorial").classList.toggle("active");
        }
      }
    },
  },
  methods: {
    init() {
      const canvas = this.$refs.canvas;
      this.sceneManager = new SceneManager(canvas, this.planets);
      this.bindEventListeners();
      this.render();
    },
    bindEventListeners() {
      window.onresize = this.resizeCanvas;
      this.resizeCanvas();
    },
    resizeCanvas() {
      const canvas = this.$refs.canvas;
      if (canvas) {
        canvas.style.width = "100%";
        canvas.style.height = "100%";
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        this.sceneManager.onWindowResize();
      }
    },
    render(time) {
      // convert time into seconds
      if (!this.isDestroy) {
        time *= 0.001;
        requestAnimationFrame(this.render);
        this.sceneManager.update(time);
        this.isResourceLoaded = this.sceneManager.isResourceLoad();
        this.isDetail = this.sceneManager.isDetail();
      }
    },
    exitDetail() {
      if (this.isDetail) {
        this.sceneManager.exitDetail();
      }
    },
    getData() {
      getSkyObjects().then((response) => {
        this.planets = response.data.body.data.object.reduce(
          (acc, curr) => ((acc[curr.name_en] = curr), acc),
          {}
        );
        this.init();
      });
    },
  },
};
</script>