/*
The SceneManager is responsible for handling the Three.js side of the app, 
which is completely hidden from the main. It knows nothing about the DOM.


SceneManager is the component responsible for managing the scene. 
It works at high level, it shouldn’t know any detail about the content of the scene.
His responsibilities are:
    * create Scene, Renderer and Camera.
    * initialize a bunch of SceneSubjects.
    * update everything at every frame.


A SceneSubject represents one entity in the scene. 
The SceneManager usually contains multiple SceneSubjects.
*/

import * as THREE from "three";
import gsap from "gsap";

import MyCameraControls from "./cameraControls/MyCameraControls.js";
import PickHelper from "./PickHelper.js";
// import "./gui/ColorGUIHelper.js";

import AmbientLight from "./sceneSubjects/AmbientLight.js";
import SunLight from "./sceneSubjects/SunLight.js";

import SolarSystem from "./sceneSubjects/SolarSystem.js";
import Stars from "./sceneSubjects/astronomicalBodies/Stars.js";

import { initLanguage } from "@/services/Language.js";

export default function SceneManager(canvas, planets) {
  // scene setup
  const screenDimensions = {
    width: canvas.width,
    height: canvas.height,
  };
  const scene = buildScene();
  const renderer = buildRender(screenDimensions);
  const sceneSubjects = createSceneSubjects(scene);

  let selected = {
    point: {},
    object: {},
    planet: {},
  };

  let isDetail = false;

  const camera = buildCamera(screenDimensions);
  const cameraControls = new MyCameraControls(camera, canvas);

  scene.background = new THREE.Color("black");

  //Picker
  const pickPosition = { x: 0, y: 0 };
  const pickHelper = new PickHelper(planets);
  clearPickPosition();

  function buildScene() {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#000");

    return scene;
  }

  function buildRender({ width, height }) {
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
    });
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);

    renderer.gammaInput = true;
    renderer.gammaOutput = true;

    return renderer;
  }

  function buildCamera({ width, height }) {
    const aspectRatio = width / height;
    const fieldOfView = 60;
    const nearPlane = 1;
    const farPlane = 10000;
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    );

    camera.position.set(0, 300, 750);
    camera.lookAt(new THREE.Vector3(0, 0, 0));

    return camera;
  }

  function createSceneSubjects(scene) {
    const sceneSubjects = [
      new SolarSystem(scene),
      new AmbientLight(scene),
      new SunLight(scene),
      new Stars(scene),
    ];

    return sceneSubjects;
  }

  // It is called by the main at every frame.
  this.update = function (time) {
    for (let i = 0; i < sceneSubjects.length; i++)
      sceneSubjects[i].update(time);

    cameraControls.update();
    renderer.render(scene, camera);
  };

  // Updates the aspect ratio of the camera and the size of the Renderer.
  // It is called by the main each time the window is resized.
  this.onWindowResize = function () {
    const { width, height } = canvas;

    screenDimensions.width = width;
    screenDimensions.height = height;

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    renderer.setSize(width, height);
  };

  // Check is resource load
  this.isResourceLoad = function () {
    return sceneSubjects[0].isResourceLoad();
  };

  // Check is detail
  this.isDetail = function () {
    return isDetail;
  };

  // Picker
  function getCanvasRelativePosition(event) {
    const rect = canvas.getBoundingClientRect();
    return {
      x: ((event.clientX - rect.left) * canvas.width) / rect.width,
      y: ((event.clientY - rect.top) * canvas.height) / rect.height,
    };
  }

  function setPickPosition(event) {
    const pos = getCanvasRelativePosition(event);
    pickPosition.x = (pos.x / canvas.width) * 2 - 1;
    pickPosition.y = (pos.y / canvas.height) * -2 + 1; // note we flip Y

    // Pick Planet
    const result = pickHelper.pick(
      pickPosition,
      sceneSubjects[0].getAstrionomicalBodies(),
      camera,
      selected
    );

    if (result) {
      selected = result;
      sceneSubjects[0].setIsRotate(false);
      changeContent();

      cameraControls.viewPlanetPoint(
        selected.point,
        selected.object,
        selected.planet
      );
    }
  }

  function clearPickPosition() {
    // unlike the mouse which always has a position
    // if the user stops touching the screen we want
    // to stop picking. For now we just pick a value
    // unlikely to pick something
    pickPosition.x = undefined;
    pickPosition.y = undefined;
  }

  function detectObject(event) {
    const pos = getCanvasRelativePosition(event);
    const mouse = {};

    mouse.x = (pos.x / canvas.width) * 2 - 1;
    mouse.y = (pos.y / canvas.height) * -2 + 1; // note we flip Y

    // Detect Planet
    const detect = pickHelper.detect(
      mouse,
      sceneSubjects[0].getAstrionomicalBodies(),
      camera
    );

    if (detect) {
      if (selected.object && selected.object.name == detect) {
        document.body.style.cursor = "grab";
      } else {
        document.body.style.cursor = "pointer";
      }
    } else {
      document.body.style.cursor = "default";
    }
  }

  // Prev Planet
  this.prevPlanet = function () {
    if (!gsap.isTweening(camera.position)) {
      const keys = Object.keys(planets);
      const prevIndex = keys.indexOf(selected.planet.name_en) - 1;
      const prevItem = keys[prevIndex];

      selected = {
        point: {},
        object: sceneSubjects[0].getPlanet(prevItem),
        planet: planets[prevItem],
      };

      sceneSubjects[0].setIsRotate(false);
      cameraControls.viewPlanet(selected.object, selected.planet);
      changeContent();
    }
  };

  // Next Planet
  this.nextPlanet = function () {
    if (!gsap.isTweening(camera.position)) {
      const keys = Object.keys(planets);
      const nextIndex = keys.indexOf(selected.planet.name_en) + 1;
      const nextItem = keys[nextIndex];

      selected = {
        point: {},
        object: sceneSubjects[0].getPlanet(nextItem),
        planet: planets[nextItem],
      };

      sceneSubjects[0].setIsRotate(false);
      cameraControls.viewPlanet(selected.object, selected.planet);
      changeContent();
    }
  };

  function changeContent() {
    isDetail = true;

    document.querySelector(".container-title").classList.add("active");
    document.querySelector(".container-arrow").classList.add("active");

    const title = document.querySelector(".container-title .title");
    const titleChild = {
      h1: title.querySelector("h1"),
      h5: title.querySelector("h5"),
    };

    const description = document.querySelector(".container-title .description");
    const descriptionChild = {
      nameObject: description.querySelector(".name-object"),
      earlyFormed: description.querySelector(".early-formed"),
      rotationSpeed: description.querySelector(".rotation-speed"),
      satelite: description.querySelector(".satelite"),
    };

    const arrow = document.querySelector(".container-arrow .arrow");
    const arrowChild = {
      prevArrow: arrow.querySelector(".prev-arrow"),
      nextArrow: arrow.querySelector(".next-arrow"),
    };

    titleChild.h1.classList.add("active");
    titleChild.h5.classList.add("active");

    descriptionChild.nameObject.classList.add("active");
    descriptionChild.earlyFormed.classList.add("active");
    descriptionChild.rotationSpeed.classList.add("active");
    descriptionChild.satelite.classList.add("active");

    arrowChild.prevArrow.classList.add("active");
    arrowChild.nextArrow.classList.add("active");

    setTimeout(() => {
      titleChild.h1.classList.remove("active");
      titleChild.h5.classList.remove("active");

      descriptionChild.nameObject.classList.remove("active");
      descriptionChild.earlyFormed.classList.remove("active");
      descriptionChild.rotationSpeed.classList.remove("active");
      descriptionChild.satelite.classList.remove("active");

      arrowChild.prevArrow.classList.remove("active");
      arrowChild.nextArrow.classList.remove("active");
    }, 1000);

    titleChild.h1.innerHTML = `<h1 data-en="${selected.planet.name_en}" data-id="${selected.planet.name_id}">${selected.planet.name_en}</h1>`;
    titleChild.h5.innerHTML = `<h5 data-en="${selected.planet.description_en}" data-id="${selected.planet.description_id}">${selected.planet.description_en}</h5>`;

    descriptionChild.nameObject.innerHTML = `<h3 data-en="${selected.planet.name_en}" data-id="${selected.planet.name_id}">${selected.planet.name_en}</h3>`;

    const formedEn =
      selected.planet.formed_en != "-"
        ? `${selected.planet.formed_en} Years Ago`
        : selected.planet.formed_en;
    const formedId =
      selected.planet.formed_id != "-"
        ? `${selected.planet.formed_id} Tahun Yang Lalu`
        : selected.planet.formed_id;

    descriptionChild.earlyFormed.innerHTML = `<h3 data-en="${formedEn}" data-id="${formedId}">${formedEn}</h3>`;

    descriptionChild.rotationSpeed.innerHTML = `<h3>${selected.planet.rotation_en}</h3>`;
    descriptionChild.satelite.textContent = `${selected.planet.satelite} Satelite`;

    const keys = Object.keys(planets);
    const prevIndex = keys.indexOf(selected.planet.name_en) - 1;
    const nextIndex = keys.indexOf(selected.planet.name_en) + 1;
    const prevItem = planets[keys[prevIndex]];
    const nextItem = planets[keys[nextIndex]];

    if (prevItem) {
      arrowChild.prevArrow.classList.remove("hidden");

      if (prevItem.name_en == "Sun") {
        arrowChild.prevArrow.querySelector(
          "span"
        ).innerHTML = `<span data-en="${prevItem.name_en}" data-id="${prevItem.name_id}">${prevItem.name_en}</span>`;
      } else {
        arrowChild.prevArrow.querySelector(
          "span"
        ).innerHTML = `<span data-en="${
          prevItem.name_en.split(" ")[1]
        }" data-id="${prevItem.name_id.split(" ")[1]}">${
          prevItem.name_en.split(" ")[1]
        }</span>`;
      }
    } else {
      arrowChild.prevArrow.classList.add("hidden");
    }

    if (nextItem) {
      if (nextItem.name_en == "Sun") {
        arrowChild.nextArrow.classList.remove("hidden");
        arrowChild.nextArrow.querySelector(
          "span"
        ).innerHTML = `<span data-en="${nextItem.name_en}" data-id="${nextItem.name_id}">${nextItem.name_en}</span>`;
      } else {
        arrowChild.nextArrow.classList.remove("hidden");
        arrowChild.nextArrow.querySelector(
          "span"
        ).innerHTML = `<span data-en="${
          nextItem.name_en.split(" ")[1]
        }" data-id="${nextItem.name_id.split(" ")[1]}">${
          nextItem.name_en.split(" ")[1]
        }</span>`;
      }
    } else {
      arrowChild.nextArrow.classList.add("hidden");
    }

    initLanguage();
  }

  function removeContent() {
    document.querySelector(".container-title").classList.remove("active");
    document.querySelector(".container-arrow").classList.remove("active");

    const title = document.querySelector(".container-title .title");
    const titleChild = {
      h1: title.querySelector("h1"),
      h5: title.querySelector("h5"),
    };

    const description = document.querySelector(".container-title .description");
    const descriptionChild = {
      nameObject: description.querySelector(".name-object"),
      earlyFormed: description.querySelector(".early-formed"),
      rotationSpeed: description.querySelector(".rotation-speed"),
      satelite: description.querySelector(".satelite"),
    };

    const arrow = document.querySelector(".container-arrow .arrow");
    const arrowChild = {
      prevArrow: arrow.querySelector(".prev-arrow"),
      nextArrow: arrow.querySelector(".next-arrow"),
    };

    titleChild.h1.classList.remove("active");
    titleChild.h5.classList.remove("active");

    descriptionChild.nameObject.classList.remove("active");
    descriptionChild.earlyFormed.classList.remove("active");
    descriptionChild.rotationSpeed.classList.remove("active");
    descriptionChild.satelite.classList.remove("active");

    arrowChild.prevArrow.classList.remove("active");
    arrowChild.nextArrow.classList.remove("active");

    sceneSubjects[0].setIsRotate(true);
  }

  this.exitDetail = function () {
    if (!gsap.isTweening(camera.position)) {
      isDetail = false;

      selected = {
        point: {},
        object: {},
        planet: {},
      };

      cameraControls.viewSolarSystem();
      removeContent();
    }
  };

  this.dispose = function () {
    renderer.dispose();
  };

  var vm = this;

  window.addEventListener("dblclick", setPickPosition);
  window.addEventListener("mouseout", clearPickPosition);
  window.addEventListener("mouseleave", clearPickPosition);
  window.addEventListener("mousemove", detectObject);
  window.addEventListener("keydown", function (e) {
    if (e.key === "Escape" && isDetail) {
      vm.exitDetail();
    }
  });

  let tapedTwice = false;

  // Mobile support
  window.addEventListener(
    "touchstart",
    (event) => {
      if (!tapedTwice) {
        tapedTwice = true;
        setTimeout(function () {
          tapedTwice = false;
        }, 300);
        return false;
      }

      event.preventDefault();
      setPickPosition(event.touches[0]);
    },
    { passive: false }
  );

  window.addEventListener("touchmove", (event) => {
    setPickPosition(event.touches[0]);
  });

  window.addEventListener("touchend", clearPickPosition);
}
